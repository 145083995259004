<template>
  <base-header>
    <template #header>
      <!--
              <a-select :defaultValue="account" v-model="account"
                        :style="{width:'320px'}" placeholder="Please select ...">
                <a-option v-for="account in accounts" :key="account.accountId" :value="account">
                  {{ account.accountId + ":" + account.companyName }}
                </a-option>
              </a-select>
              -->
      <a-breadcrumb style="flex-grow:0;flex-shrink: 0;margin-left: 20px;" v-if="showBreadcrumb">
        <a-breadcrumb-item>
          <a-link @click="$router.push({path:'/accounts'})">Accounts</a-link>
        </a-breadcrumb-item>
        <template v-if="accountId">
          <a-breadcrumb-item>
            <a-link @click="$router.push({path:`/accounts/${accountId}`})">{{ accountId }}</a-link>
          </a-breadcrumb-item>
          <template v-if="tenantId">
            <a-breadcrumb-item>
              <a-link disabled>tenants</a-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
              <a-link @click="$router.push({path:`/accounts/${accountId}/tenants/${tenantId}`})">{{ tenantId }}
              </a-link>
            </a-breadcrumb-item>
          </template>
        </template>
      </a-breadcrumb>
    <!--
      <a-link @click="$router.push({path:'/accounts'})"
              style="flex-grow:0;flex-shrink: 0;margin-left: 20px;">My Accounts
      </a-link>
      <a-link v-if="accountId" @click="$router.push({path:`/accounts/${accountId}`})"
              style="flex-grow:0;flex-shrink: 0;padding-left: 20px;">{{ accountId }}
      </a-link>
      -->
    </template>

    <template #leftSider>
      <a-layout-sider class="my-sider" collapsible breakpoint="xl">
        <!--        <div class="sider-top">-->
        <!--          <div class="sider-top-inner"></div>-->
        <!--        </div>-->
        <div class="logo" />
        <a-menu
          :default-open-keys="['iot-essence']"
          :default-selected-keys="['accounts']"
          :style="{ width: '100%' }"
          @menu-item-click="onClickMenuItem"
        >
          <!-- Account维度的菜单 -->
          <template v-if="accountId">
            <a-menu-item key="AccountDetail">
              <IconHome></IconHome>
              Account Info
            </a-menu-item>
          </template>

          <!-- 项目维度的菜单 -->
          <template v-if="accountId && tenantId">
            <a-menu-item key="TenantDetail">
              <IconHome></IconHome>
              Product Info
            </a-menu-item>
            <a-sub-menu key="IotEssence">
              <template #title>
                <IconVideoCamera></IconVideoCamera>
                Iot Essence
              </template>
              <a-menu-item key="PirConfig">PIR Config</a-menu-item>
              <a-menu-item key="Webhooks">Webhooks</a-menu-item>
              <a-menu-item key="NotificationCert">Notification Cert</a-menu-item>
              <!--
              <a-menu-item key="DeviceConfig">Device Config</a-menu-item>
              -->
            </a-sub-menu>
            <a-menu-item key="UniSmart">
              <IconSend></IconSend>
              Uni-Smart
            </a-menu-item>
          </template>

          <a-sub-menu key="Experiments">
            <template #title>
              <IconVideoCamera></IconVideoCamera>
              Experiments
            </template>
            <a-menu-item key="UniSmartDemo">Uni-Smart Demo</a-menu-item>
          </a-sub-menu>

        </a-menu>
        <!-- trigger -->
        <template #trigger="{ collapsed }">
          <IconCaretRight v-if="collapsed"></IconCaretRight>
          <IconCaretLeft v-else></IconCaretLeft>
        </template>
      </a-layout-sider>
    </template>

    <template #content>
      <router-view />
    </template>
  </base-header>
</template>
<script>
import BaseHeader from "@/components/baseHeader";
import { defineComponent } from "vue";

import {
  IconCaretRight,
  IconCaretLeft,
  IconHome,
  IconVideoCamera,
  IconSend
} from "@arco-design/web-vue/es/icon";

export default defineComponent({
  components: {
    IconCaretRight,
    IconCaretLeft,
    IconHome,
    IconVideoCamera,
    IconSend,
    BaseHeader
  },
  data() {
    return {
      showBreadcrumb: false,
      // accounts: [],
      // account: null,
      accountId: null,
      tenantId: null
    };
  },
  computed: {
    // ...mapGetters({
    //   currentAccount: "currentAccount"
    // })
  },
  methods: {
    onClickMenuItem(key) {
      // Message.info({ content: `You select ${key}`, showIcon: true });
      console.log(`left menu: select ${key}`);
      if (key === "UniSmartDemo") {
        this.$router.push({ name: key });
      } else {
        this.$router.push({ name: key, params: { accountId: this.accountId, tenantId: this.tenantId } });
      }
    },
    updateTenantId(to) {
      console.log("beforeEach to=", to);
      /*
      const prefix = "/tenants/";
      if (to.path.startsWith(prefix)) {
        this.tenantId = to.params.tenantId;
      } else {
        this.tenantId = null;
      }
       */
      this.accountId = to.params.accountId || null;
      this.tenantId = to.params.tenantId || null;
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push({ path: "/login" });
    }
  },
  beforeCreate() {
    console.log("home beforeCreate!");
  },
  async created() {
    console.log("home created!");
    // this.accounts = await this.queryAccounts();
    // if (this.accounts.length > 0) {
    //   this.account = this.accounts[0];
    // }
    this.updateTenantId(this.$route);
    this.$router.beforeEach((to, from) => {
      this.updateTenantId(to);
      return true;
    });
  },
  beforeMount() {
    console.log("home beforeMount!");
  },
  mounted() {
    console.log("home mounted!");
  },
  beforeUnmount() {
    console.log("home beforeUnmount!");
  },
  unmounted() {
    console.log("home unmounted!");
  },
  watch: {
    currentAccount(newVal, oldVal) {
      console.log("currentAccount ", oldVal, "->", newVal);
    }
  }
});
</script>
<style lang="scss" scoped>

.main-layout {
  height: 100%;
  background: #F2F3F5;
}

.my-header {
  width: 100%;
  height: 58px;
  box-sizing: content-box;
  padding-bottom: 16px;
  background: #F2F3F5;
}

.my-header-content {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  box-shadow: 0px -1px 0px 0px #E5E6E8;

  //padding-left: 20px;
}

.layout-demo {
  background: #F2F3F5;
}

.my-sider {
  border-radius: 0 16px 16px 0 !important;
  //border-top-right-radius: 16px;
  //margin-top: 16px;
}

.content-layout {
  width: 100%;
  height: 100%;
  padding: 0 16px 16px 16px;
  background: #F2F3F5;
}

.my-content {
  width: 100%;
  height: 100%;
  //border-radius: 16px;
  //background: #FFFFFF;

  overflow: auto;
  //align-items: flex-start;
  //justify-content: flex-start;
}

.right-dropdown {
  align-self: flex-end;
}
</style>
